// Generated by Framer (4f5e97b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {sscNaFdAp: {hover: true}};

const cycleOrder = ["sscNaFdAp"];

const serializationHash = "framer-rCY8W"

const variantClassNames = {sscNaFdAp: "framer-v-ka9k5n"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, threads, width, ...props}) => { return {...props, O8av2chFz: threads ?? props.O8av2chFz} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;threads?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, O8av2chFz, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "sscNaFdAp", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rCY8W", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={O8av2chFz} openInNewTab><Image {...restProps} as={"a"} background={{alt: "Threads", fit: "fit", intrinsicHeight: 64, intrinsicWidth: 56, pixelHeight: 64, pixelWidth: 56, src: "https://framerusercontent.com/images/9pvytMl2sKoYMREPVcSC8RUEQVM.svg"}} className={`${cx("framer-ka9k5n", className)} framer-1efced4`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"sscNaFdAp"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"sscNaFdAp-hover": {opacity: 0.8}}} {...addPropertyOverrides({"sscNaFdAp-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rCY8W [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rCY8W .framer-1efced4 { display: block; }", ".framer-rCY8W .framer-ka9k5n { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: 32px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-rCY8W .framer-ka9k5n { gap: 0px; } .framer-rCY8W .framer-ka9k5n > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-rCY8W .framer-ka9k5n > :first-child { margin-left: 0px; } .framer-rCY8W .framer-ka9k5n > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"TFx7aCOxh":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"O8av2chFz":"threads"}
 * @framerImmutableVariables true
 */
const FramerIugOnhlbc: React.ComponentType<Props> = withCSS(Component, css, "framer-rCY8W") as typeof Component;
export default FramerIugOnhlbc;

FramerIugOnhlbc.displayName = "Social Icons / Threads - on Dark";

FramerIugOnhlbc.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerIugOnhlbc, {O8av2chFz: {title: "Threads", type: ControlType.Link}} as any)

addFonts(FramerIugOnhlbc, [])